import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">

<path d="M5236 9931 c-19 -7 -19 -8 3 -14 27 -7 53 1 45 14 -6 11 -21 11 -48
0z"/>
<path d="M5135 9900 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M7230 9870 c0 -5 9 -10 21 -10 11 0 17 5 14 10 -3 6 -13 10 -21 10
-8 0 -14 -4 -14 -10z"/>
<path d="M5038 9863 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5548 9863 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4955 9840 c-3 -6 5 -10 19 -10 14 0 28 5 31 10 3 6 -5 10 -19 10
-14 0 -28 -4 -31 -10z"/>
<path d="M6990 9805 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
<path d="M5760 9790 c0 -13 18 -22 24 -11 3 5 -1 11 -9 15 -8 3 -15 1 -15 -4z"/>
<path d="M5670 9729 c6 -11 16 -19 21 -17 17 6 12 22 -10 30 -19 7 -20 5 -11
-13z"/>
<path d="M7330 9706 c0 -9 30 -14 35 -6 4 6 -3 10 -14 10 -12 0 -21 -2 -21 -4z"/>
<path d="M5725 9670 c-4 -6 5 -10 19 -10 14 0 26 5 26 10 0 6 -9 10 -19 10
-11 0 -23 -4 -26 -10z"/>
<path d="M7430 9670 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4410 9565 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M4175 9430 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4765 9410 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M6430 9408 c-25 -5 -85 -12 -135 -17 -49 -5 -106 -15 -125 -24 -19
-8 -49 -13 -66 -10 -20 3 -35 -1 -43 -11 -8 -10 -30 -16 -55 -16 -22 -1 -52
-7 -66 -15 -14 -8 -41 -14 -61 -15 -20 0 -42 -7 -49 -15 -7 -8 -20 -15 -29
-15 -9 0 -48 -13 -86 -30 -39 -16 -80 -30 -92 -30 -12 0 -23 -3 -25 -7 -3 -8
-191 -103 -203 -103 -25 0 -410 -253 -470 -309 -22 -20 -60 -53 -85 -73 -57
-46 -110 -95 -110 -102 0 -3 9 -6 20 -6 11 0 20 4 20 9 0 13 315 221 334 221
9 0 16 5 16 10 0 6 8 13 18 16 9 3 58 25 107 49 130 62 175 81 246 105 35 12
78 27 94 34 39 17 106 35 175 47 30 6 57 14 58 19 2 6 21 10 43 10 22 0 56 7
76 15 19 8 59 15 87 15 29 0 78 7 109 15 83 21 617 21 697 0 30 -8 78 -15 106
-15 29 0 65 -7 80 -15 16 -8 49 -15 74 -15 25 0 54 -7 64 -15 11 -8 33 -15 50
-15 17 0 48 -6 71 -14 22 -8 78 -26 125 -41 47 -15 99 -33 115 -40 17 -8 63
-28 104 -45 84 -36 253 -124 293 -151 14 -11 29 -19 32 -19 17 0 316 -211 353
-248 10 -10 59 -53 109 -95 51 -43 119 -110 152 -150 33 -40 75 -87 92 -106
18 -19 65 -80 106 -135 40 -56 77 -105 82 -111 25 -31 126 -209 188 -330 l72
-140 132 -3 c74 -1 137 1 142 6 12 12 -41 168 -97 282 -25 52 -54 111 -63 130
-14 28 -75 138 -110 197 -21 35 -145 206 -228 314 -63 83 -279 296 -376 372
-47 37 -88 71 -90 75 -2 4 -15 14 -29 22 -15 8 -73 47 -130 85 -57 39 -142 90
-189 114 -47 24 -98 52 -115 61 -51 28 -227 101 -295 123 -36 11 -74 26 -85
33 -11 8 -33 14 -50 14 -17 0 -40 7 -51 15 -10 8 -35 15 -54 15 -19 0 -46 7
-60 15 -14 8 -46 14 -71 15 -26 0 -60 7 -75 15 -17 8 -55 15 -90 15 -33 0 -83
7 -110 15 -38 11 -102 15 -289 14 -132 -1 -260 -6 -285 -11z"/>
<path d="M4120 9385 c-10 -12 -10 -15 3 -15 8 0 17 7 21 15 3 8 2 15 -3 15 -5
0 -14 -7 -21 -15z"/>
<path d="M4670 9340 c0 -13 5 -20 13 -17 6 2 12 10 12 17 0 7 -6 15 -12 18 -8
2 -13 -5 -13 -18z"/>
<path d="M4710 9286 c0 -10 9 -16 21 -16 24 0 21 23 -4 28 -10 2 -17 -3 -17
-12z"/>
<path d="M4880 9290 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M3945 9260 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M8350 9260 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2 -2
-10z"/>
<path d="M3915 9230 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M8395 9230 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4705 9180 c-3 -5 3 -10 14 -10 12 0 21 5 21 10 0 6 -6 10 -14 10 -8
0 -18 -4 -21 -10z"/>
<path d="M4553 9055 c-9 -25 2 -27 22 -5 17 18 17 20 1 20 -9 0 -19 -7 -23
-15z"/>
<path d="M3715 9050 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3636 8963 c-15 -15 -23 -29 -17 -31 9 -3 61 44 61 54 0 12 -21 0
-44 -23z"/>
<path d="M3710 8919 c0 -11 4 -18 10 -14 5 3 7 12 3 20 -7 21 -13 19 -13 -6z"/>
<path d="M3650 8880 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3740 8880 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4345 8860 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3770 8850 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3480 8790 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3435 8730 c-22 -43 -18 -49 10 -15 26 32 31 45 15 45 -5 0 -16 -13
-25 -30z"/>
<path d="M3365 8650 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3970 8645 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
<path d="M3216 8421 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M3205 8251 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"/>
<path d="M3470 8150 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M2992 8000 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3270 7945 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
<path d="M3217 7873 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M3650 7816 c0 -31 6 -34 13 -6 3 11 1 23 -4 26 -5 3 -9 -6 -9 -20z"/>
<path d="M3505 7720 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3010 7685 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
<path d="M2890 7538 c-12 -22 -12 -22 6 -6 10 10 15 20 12 24 -4 3 -12 -5 -18
-18z"/>
<path d="M1840 7313 c-260 -46 -458 -217 -532 -460 -30 -97 -30 -290 -1 -389
52 -178 162 -313 323 -396 136 -71 361 -98 497 -60 258 73 417 223 487 461 25
84 22 306 -5 391 -66 213 -231 372 -454 436 -58 17 -256 28 -315 17z m210
-249 c132 -20 259 -151 288 -299 43 -214 -37 -401 -210 -492 -49 -25 -62 -28
-168 -28 -106 0 -119 2 -171 28 -104 52 -187 162 -209 278 -32 167 10 319 117
420 61 58 119 86 200 98 58 9 66 8 153 -5z"/>
<path d="M2912 7290 l-22 -9 2 -628 3 -628 450 0 450 0 3 90 c2 50 0 100 -3
112 l-5 23 -303 2 -302 3 -3 510 c-2 465 -4 511 -19 523 -20 14 -215 16 -251
2z"/>
<path d="M4008 7293 l-28 -4 0 -367 c0 -431 7 -508 50 -621 57 -148 187 -258
345 -292 73 -16 272 -17 337 -1 187 45 321 181 372 377 13 50 16 133 16 479 1
282 -3 421 -10 426 -14 9 -176 12 -222 4 l-38 -6 0 -366 c-1 -393 -8 -480 -49
-559 -42 -79 -122 -122 -231 -123 -114 0 -188 36 -235 116 -47 81 -55 160 -55
564 l0 368 -22 6 c-25 6 -186 6 -230 -1z"/>
<path d="M5442 7290 l-22 -9 2 -628 3 -628 140 0 140 0 5 170 5 170 195 6
c230 7 310 26 410 96 107 75 164 180 176 328 20 241 -125 426 -377 481 -89 19
-639 31 -677 14z m653 -261 c69 -35 104 -89 112 -173 10 -106 -29 -180 -120
-228 -38 -21 -56 -23 -207 -23 l-165 0 -3 215 c-1 118 0 221 3 228 4 11 37 13
168 10 151 -3 165 -5 212 -29z"/>
<path d="M6760 7274 c-12 -31 -13 -1223 -2 -1242 7 -9 42 -12 143 -10 l134 3
5 255 5 255 280 0 280 0 5 -255 5 -255 138 -3 137 -3 0 635 0 634 -22 5 c-47
10 -217 8 -237 -3 -20 -10 -21 -19 -21 -241 0 -140 -4 -238 -10 -250 -10 -18
-24 -19 -273 -19 -191 0 -266 3 -275 12 -9 9 -12 78 -12 250 l0 238 -26 10
c-14 6 -75 10 -135 10 -108 0 -110 0 -119 -26z"/>
<path d="M8249 7293 c-10 -2 -19 -19 -23 -41 -8 -49 -8 -1146 0 -1195 l7 -37
471 2 471 3 0 110 0 110 -330 5 -330 5 -3 143 -3 142 281 0 280 0 11 27 c5 14
8 65 7 112 l-3 86 -285 5 c-157 3 -286 7 -288 8 -1 1 -1 64 0 140 l3 137 320
5 c176 3 321 7 323 8 1 1 2 49 2 107 0 93 -2 105 -20 115 -18 10 -853 13 -891
3z"/>
<path d="M9472 7293 l-22 -4 2 -632 3 -632 125 0 125 0 3 348 c2 308 4 347 18
347 15 0 53 -37 54 -53 0 -6 99 -175 201 -342 11 -16 23 -39 28 -51 5 -11 23
-41 40 -66 28 -42 35 -46 79 -50 26 -2 55 -2 65 0 9 2 43 48 73 101 31 53 99
167 150 254 52 87 94 160 94 162 0 2 10 20 21 40 16 27 25 33 33 25 7 -7 13
-124 16 -363 l5 -352 125 0 125 0 0 630 0 629 -32 9 c-17 5 -69 6 -115 3 l-83
-7 -59 -102 c-69 -120 -232 -402 -261 -451 -11 -19 -33 -58 -49 -87 -47 -88
-76 -129 -89 -129 -7 0 -43 53 -81 118 -38 64 -107 182 -154 262 -47 80 -101
172 -122 205 -20 33 -52 88 -70 121 l-33 61 -60 7 c-63 6 -117 6 -155 -1z"/>
<path d="M5554 5769 c-54 -15 -69 -42 -69 -121 0 -77 3 -81 115 -162 40 -29
45 -36 45 -72 0 -35 -3 -39 -27 -42 -27 -3 -28 -2 -28 47 l0 51 -55 0 -55 0 0
-44 c0 -62 14 -93 52 -116 56 -34 169 -22 205 22 20 23 26 98 14 145 -9 33
-23 51 -59 77 -103 76 -103 76 -100 110 2 23 8 31 23 31 15 0 21 -8 23 -32 3
-33 3 -33 59 -33 l56 0 -7 48 c-7 53 -28 76 -84 92 -43 12 -66 12 -108 -1z"/>
<path d="M6641 5767 c-66 -23 -76 -50 -79 -211 -4 -172 6 -219 51 -246 64 -39
176 -18 207 40 6 11 13 49 17 85 l6 65 -61 0 -61 0 -3 -62 c-2 -51 -6 -63 -21
-66 -16 -3 -17 8 -15 160 3 137 5 163 18 163 11 0 16 -15 18 -62 l3 -63 59 0
60 0 -1 48 c0 67 -19 113 -54 133 -41 24 -103 31 -144 16z"/>
<path d="M6971 5767 c-19 -6 -44 -25 -55 -40 -19 -26 -21 -42 -21 -191 0 -178
7 -206 57 -232 15 -8 50 -14 78 -14 43 0 55 5 88 34 l37 34 3 149 c4 164 -6
214 -48 244 -33 24 -97 32 -139 16z m69 -238 c0 -135 -2 -159 -15 -159 -13 0
-15 24 -15 158 0 87 3 162 6 165 19 18 24 -19 24 -164z"/>
<path d="M8135 5773 c-41 -11 -75 -44 -86 -85 -6 -25 -9 -99 -7 -182 2 -114 6
-146 21 -167 35 -51 113 -65 154 -27 20 18 23 19 23 5 0 -13 9 -17 40 -17 l40
0 0 130 0 130 -70 0 -70 0 0 -35 c0 -19 5 -35 10 -35 7 0 10 -22 8 -57 -2 -44
-7 -58 -18 -58 -13 0 -15 24 -15 160 0 136 2 160 15 160 11 0 16 -13 18 -47
l3 -48 60 0 59 0 0 35 c0 52 -24 102 -58 119 -34 18 -97 27 -127 19z"/>
<path d="M4310 5537 l0 -237 109 0 c102 0 112 2 138 24 l28 24 0 164 c0 89 -3
175 -8 190 -12 46 -44 58 -161 65 l-106 6 0 -236z m148 141 c8 -8 12 -54 12
-139 0 -135 -4 -159 -26 -159 -11 0 -14 29 -14 155 0 85 4 155 8 155 4 0 13
-5 20 -12z"/>
<path d="M4670 5763 c0 -4 -14 -107 -30 -228 -16 -121 -30 -223 -30 -227 0 -5
27 -8 59 -8 l60 0 3 38 c3 32 7 37 31 40 26 3 27 1 27 -37 l0 -41 63 0 63 0
-36 233 -35 232 -88 3 c-48 1 -87 -1 -87 -5z m109 -270 c1 -26 -3 -33 -18 -33
-20 0 -21 12 -4 130 l7 45 7 -55 c4 -30 7 -70 8 -87z"/>
<path d="M4910 5726 c0 -43 1 -45 33 -48 l32 -3 3 -187 2 -188 60 0 60 0 0
190 0 190 36 0 35 0 -3 43 -3 42 -127 3 -128 3 0 -45z"/>
<path d="M5230 5763 c0 -11 -48 -379 -56 -425 l-6 -38 61 0 61 0 6 40 c6 34
10 40 30 40 21 0 24 -5 24 -40 l0 -40 63 0 64 0 -19 123 c-10 67 -26 172 -36
232 l-17 110 -88 3 c-48 1 -87 -1 -87 -5z m109 -265 c1 -30 -3 -38 -17 -38
-16 0 -17 7 -10 78 3 42 8 84 9 92 2 19 17 -84 18 -132z"/>
<path d="M5800 5575 c0 -216 6 -242 60 -270 41 -21 118 -19 157 5 45 27 53 67
53 278 l0 182 -60 0 -60 0 0 -200 c0 -171 -2 -200 -15 -200 -13 0 -15 29 -15
200 l0 200 -60 0 -60 0 0 -195z"/>
<path d="M6130 5536 l0 -236 114 0 c154 0 161 5 161 125 0 84 -1 88 -30 111
-22 17 -25 24 -13 24 42 0 61 79 34 145 -20 47 -45 57 -162 63 l-104 5 0 -237z
m149 140 c18 -22 10 -90 -11 -94 -15 -3 -18 5 -18 52 0 57 8 68 29 42z m-1
-175 c9 -5 12 -25 10 -62 -2 -42 -7 -55 -20 -57 -16 -3 -18 5 -18 62 0 66 3
73 28 57z"/>
<path d="M7200 5535 l0 -235 55 0 55 0 0 142 c0 78 2 139 5 137 3 -4 35 -244
35 -271 0 -5 16 -8 35 -8 19 0 35 3 35 8 0 27 32 267 35 271 3 2 5 -59 5 -137
l0 -142 55 0 55 0 0 235 0 236 -72 -3 -73 -3 -12 -65 c-30 -160 -27 -157 -49
-40 l-19 105 -73 3 -72 3 0 -236z"/>
<path d="M7730 5535 l0 -235 55 0 55 0 1 103 c0 114 -2 117 43 -45 l17 -58 49
0 50 0 0 235 0 235 -55 0 -55 0 -1 -102 -1 -103 -35 103 -35 102 -44 0 -44 0
0 -235z"/>
<path d="M9260 5482 l-125 -3 -22 -35 c-13 -20 -23 -41 -23 -48 0 -8 -7 -19
-15 -26 -8 -7 -15 -19 -15 -27 0 -7 -20 -49 -45 -93 -25 -44 -45 -81 -45 -84
0 -5 -114 -174 -173 -256 -96 -135 -319 -379 -398 -436 -22 -16 -62 -48 -87
-71 -49 -43 -179 -143 -188 -143 -2 0 -41 -24 -87 -52 -45 -29 -91 -59 -102
-65 -25 -16 -281 -142 -315 -155 -14 -5 -38 -16 -53 -24 -16 -8 -33 -14 -39
-14 -5 0 -24 -6 -41 -14 -37 -16 -99 -36 -177 -59 -30 -8 -80 -22 -111 -31
-31 -9 -67 -16 -82 -16 -14 0 -38 -5 -54 -11 -15 -7 -64 -15 -108 -19 -44 -4
-98 -13 -120 -19 -65 -17 -578 -14 -648 4 -32 8 -80 15 -106 15 -27 0 -68 7
-91 15 -23 8 -56 15 -72 15 -17 0 -53 7 -82 16 -28 9 -64 20 -81 24 -81 22
-180 54 -204 66 -14 8 -31 14 -38 14 -6 0 -24 7 -40 15 -15 8 -64 28 -108 46
-128 51 -398 204 -465 264 -31 29 -90 58 -90 46 0 -22 208 -197 325 -274 22
-15 63 -42 90 -62 28 -19 53 -35 56 -35 4 0 36 -17 70 -39 80 -48 240 -125
349 -168 99 -39 305 -104 339 -108 22 -2 49 -8 60 -14 11 -6 34 -11 51 -11 17
0 47 -7 67 -15 19 -8 56 -15 82 -15 26 0 73 -7 104 -15 71 -18 607 -22 654 -4
15 6 64 14 108 19 119 13 187 29 400 90 61 18 114 36 120 41 5 5 17 9 27 9 9
0 34 7 55 16 21 9 52 22 68 29 60 25 342 169 395 203 301 190 589 461 823 774
15 20 27 41 27 45 0 9 13 27 58 83 12 15 22 31 22 35 0 4 27 54 60 112 33 57
60 108 60 113 0 5 13 33 28 62 16 29 39 82 51 118 13 36 26 70 31 75 4 6 11
29 15 51 4 30 3 42 -7 45 -7 2 -69 3 -138 1z"/>
<path d="M6450 5345 l0 -45 45 0 45 0 0 45 0 45 -45 0 -45 0 0 -45z"/>
<path d="M7610 5345 l0 -45 45 0 45 0 0 45 0 45 -45 0 -45 0 0 -45z"/>
<path d="M2900 5334 c0 -14 4 -23 9 -20 5 3 7 15 4 26 -7 28 -13 25 -13 -6z"/>
<path d="M3620 5310 c0 -11 4 -20 9 -20 5 0 7 9 4 20 -3 11 -7 20 -9 20 -2 0
-4 -9 -4 -20z"/>
<path d="M3652 5240 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2992 5065 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M3842 4811 c2 -7 7 -12 11 -12 12 1 9 15 -3 20 -7 2 -11 -2 -8 -8z"/>
<path d="M3880 4746 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M3160 4694 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M3971 4624 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4030 4525 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"/>
<path d="M4085 4450 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4772 4348 c5 -15 28 -23 28 -10 0 5 -7 13 -16 16 -10 4 -14 1 -12
-6z"/>
<path d="M4230 4273 c0 -12 8 -23 20 -26 11 -3 20 -3 20 -1 0 3 -9 14 -20 26
-20 22 -20 22 -20 1z"/>
<path d="M4275 4220 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M4580 3910 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4780 3760 c0 -5 9 -10 21 -10 11 0 17 5 14 10 -3 6 -13 10 -21 10
-8 0 -14 -4 -14 -10z"/>
<path d="M8230 3590 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M5120 3556 c0 -9 30 -14 35 -6 4 6 -3 10 -14 10 -12 0 -21 -2 -21 -4z"/>
<path d="M5198 3523 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8055 3470 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"/>
<path d="M5358 3433 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M7775 3380 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M5595 3350 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M5665 3320 c3 -5 12 -10 20 -10 8 0 17 5 20 10 4 6 -5 10 -20 10 -15
0 -24 -4 -20 -10z"/>
<path d="M5368 3293 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7500 3290 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M4645 3270 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M4730 3240 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M4780 3210 c20 -13 43 -13 35 0 -3 6 -16 10 -28 10 -18 0 -19 -2 -7
-10z"/>
<path d="M6145 3210 c-4 -6 7 -10 24 -10 17 0 31 5 31 10 0 6 -11 10 -24 10
-14 0 -28 -4 -31 -10z"/>
<path d="M7070 3210 c-11 -7 -2 -10 33 -10 30 0 46 4 42 10 -8 13 -55 13 -75
0z"/>
<path d="M5015 3120 c3 -5 14 -10 23 -10 15 0 15 2 2 10 -20 13 -33 13 -25 0z"/>
<path d="M5077 3099 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M6988 3003 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6835 2980 c-30 -8 -30 -8 8 -9 23 -1 36 3 32 9 -3 6 -7 9 -8 9 -1
-1 -15 -5 -32 -9z"/>
<path d="M6645 2950 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
